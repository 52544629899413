import Exec from "../Exec";
import { IDataType } from "src/engine/SourceCenter/Manager";
import IHttp from "src/lib/http/IHttp";
import Http from "src/lib/http/Http";
import PropsUtils from "src/engineEditor/props/utils/PropsUtils";
import GameConfig from "src/GameConfig";
import Utils from "src/lib/utils/Utils";
import Config from "src/engine/const/Config";
import IUIItem from "src/engine/interface/IUIItem";
import { Toast } from "antd-mobile";
import StorageType from "src/lib/utils/storage/StorageType";
import Storage from "src/lib/utils/storage/Storage";

interface IProps {
    uiItem: IUIItem;
    http: IHttp;
    useTimestamp?: boolean;
    notSetGameId?: boolean;
    errorToastMSG?: string;
    errorNetMsg?: string;
    headersToArray?: boolean;
    // 是否可以同时多次触发SourceNet
    requestRepeat?: boolean;
    prefix?: string;
    dataSource?: any;
    postArgs?: string[];
    // 是否不合并dataSource
    notMergeDataSource?: boolean;
}

/**
 *
 *
 * @class Handle
 */
class Handle extends Exec {
    /**
     * 初始化参数
     * @param data
     * @param props
     */
    init(data: any, props: IProps): IDataType {
        const { loading = false } = props.uiItem.props;

        return { loading };
    }

    storages = {};

    getStorage(type: StorageType) {
        let storage = this.storages[type];
        if (!storage) {
            storage = this.storages[type] = new Storage(Number(type));
        }
        return storage;
    }

    syncKey(key: string = "area", sync: Array<StorageType | any> = [], saveValue?: any) {
        for (let item of sync) {
            let toNum = false;
            if (typeof item === "object") {
                key = item.key || key;
                if (item.toNumber) toNum = true;
                item = item.type;
            }
            const storages = this.getStorage(item);
            let value = storages.get(key);

            if (typeof value !== "undefined") {
                if (typeof value === "string") {
                    if (value.indexOf("{") > -1 || value.indexOf("[") > -1) {
                        value = JSON.parse(value);
                    }
                }
                if (toNum) value = Number(value);
                return value;
            }
        }
        return;
    }

    handleInitHeaders(headers: any, props: IProps) {
        // const { http } = props;
        if (Array.isArray(headers)) {
            const ret = {};
            headers.map((header) => {
                ret[header.label] = this.syncKey(header.type, header.sync) || header.value;
            });
            headers = ret;
        }
        return headers;
    }

    handleHeaders(headers: any, props: any) {
        return headers;
    }

    private parserData(ret: any, data: IDataType) {
        return { data: ret };
    }

    propsListener() {
        return [...super.propsListener(), "http.url", "http.method", "initHeaders"];
    }

    propsUpdate() {
        this.mHandle.refresh();
    }

    mNetCount: number = 1;

    getHttp(props: IProps, headers: any) {
        return props.http;
    }

    handleDataRight(data: IDataType) {}

    exec(headers: any, data: IDataType, props: IProps): any {
        let http = this.getHttp(props, headers);
        const {
            useTimestamp,
            notSetGameId = false,
            notMergeDataSource = false,
            postArgs = [],
            errorNetMsg = "网络出现问题，请重试",
            errorToastMSG,
            requestRepeat = false,
            prefix = "/cloudplt/api",
            dataSource: toDataSource,
        } = props;
        const keyIndex = this.mNetCount + 1;

        this.mNetCount = keyIndex;
        const { end, ...dataSource } = toDataSource || {};
        let other = {};
        if (!notMergeDataSource) other = { ...dataSource };
        if (!notSetGameId) {
            http = { ...http, headers: { gameId: GameConfig.gameId + "", sname: GameConfig.server, ...other, ...headers } };
        } else {
            http = { ...http, headers: { ...other, ...headers } };
        }
        if (http) {
            if (notSetGameId !== true) {
                //处理
                http.url = Utils.setUrlParams("sname", "[sname]", http.url);
                //处理
                http.url = Utils.setUrlParams("gameId", "[gameId]", http.url);
            }
            if (postArgs) {
                postArgs.map((arg: string) => {
                    http.url = Utils.setUrlParams(arg, `[${arg}]`, http.url);
                });
            }
            http.url = PropsUtils.handleUrl(http.url, http.headers, true);
            http.url = http.url && (http.url.indexOf("http") === 0 || http.url.indexOf("//") === 0) ? http.url : Config.API + http.url;
            if (typeof prefix !== "undefined") http.url = http.url.replace("/cloudplt/api", prefix);

            if (useTimestamp) http.url = Utils.setUrlParams("ts", new Date().valueOf(), http.url);
            //处理url
            if (!notSetGameId) {
                if (http.headers?.gameId) {
                    delete http.headers.gameId;
                }
                if (http.headers?.sname) {
                    delete http.headers.sname;
                }
            }
            // 发送的 header 数据由 map 转成 list;
            if (props.headersToArray) http.headers = Utils.objectValues(http.headers);
            return new Promise((resolve, reject) => {
                 if (http.headers) {
                    delete http.headers.end;
                    delete http.headers.CODE;
                }
                Http.ajax(http).then(
                    (ret: any) => {
                        ret = ret || {};
                        ret.CODE = ret.CODE ?? 200;
                        if (keyIndex === this.mNetCount || requestRepeat) {
                            const retData = { ...data, ...this.parserData(ret, data), headers, loading: false };
                            resolve({ ...data, ...this.parserData(ret, data), headers, loading: false });
                            this.handleDataRight(retData);
                        }
                    },
                    (err: any) => {
                        err.data = err.data || {};
                        if (keyIndex === this.mNetCount || requestRepeat) {
                            resolve({
                                loading: false,
                                data: {
                                    CODE: (err && err.status) || 500,
                                    REV: false,
                                    MSG: (err && err.MSG) || (err && err.error) || errorNetMsg,
                                    ...err.data,
                                    headers,
                                },
                                headers,
                            });
                            if (errorToastMSG) Toast.show(errorToastMSG, 3, true);
                        }
                    }
                );
            });
        }
        return { ...data, data: { ...data.data, ...headers } };
    }
}

export default Handle;
