import "src/components/PCPageMgr";import "src/components/Layout";import "src/components/View";import "src/components/ButtonExt";import "src/components/Text";import "src/component/script/ClickGoUrl";import "src/component/script/CallbackScript";import "src/component/jj/JSwiper";import "src/component/script/BaseProps";import "src/component/script/GGEdge";import "src/component/script/AggregationScript";import "src/component/script/CheckScript";import "src/component/script/ArrayHandleScript";import "src/component/script/StringScript";import "src/component/script/AnchorScript";import "src/component/script/ImmediateScript";import "src/component/jj/JA";import "src/components/ImportPage";import "src/component/script/ClickScript";import "src/component/source/SourceNet";
        const UILoadingManifest = (key: string) => {
            switch (key) {
                
                default:
                    return null;
            }
        };
        
        UILoadingManifest.PageManifest ={
            index:["PCPage","View","ButtonExt","Text","ClickGoUrl","CallbackScript","JSwiper","BaseProps","GGEdge","AggregationScript","CheckScript","ArrayHandleScript","StringScript","ImportPage","ClickScript"],about:["PCPage","View","ButtonExt","AnchorScript","BaseProps","ImmediateScript","Text","GGEdge","AggregationScript","ImportPage","ClickScript","CallbackScript"],layout:["PCPage","View","ButtonExt","AnchorScript","BaseProps","ImmediateScript","Text","GGEdge","AggregationScript","JSwiper","CallbackScript","ClickGoUrl","CheckScript","ArrayHandleScript","StringScript","ImportPage","ClickScript"],joinus:["PCPage","View","ButtonExt","AnchorScript","BaseProps","ImmediateScript","Text","GGEdge","AggregationScript","ImportPage","ClickScript","CallbackScript"],contactus:["PCPage","View","ButtonExt","AnchorScript","BaseProps","ImmediateScript","Text","JA","GGEdge","AggregationScript","ImportPage","ClickScript","CallbackScript"],bottom:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript"],PageMgrUIS:["PCPage","SourceNet"],
        }

        export default UILoadingManifest;